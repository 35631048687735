.wrapper {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
    display: flex;
  }
  .map {
    flex-flow: column;
    flex: 1;
  }
  .detail {
    width: 35em;
    overflow: auto;
  }
}
