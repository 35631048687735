.column {
  display: flex;
  flex-direction: column;
  margin: 0 -2rem -1rem -2rem;

  .button {
    text-transform: capitalize;
    margin-left: -1rem;
    text-align: left;
    padding-left: 3rem;

    &:first-of-type {
      margin-top: -1rem;
    }
  }
}

.item {
  padding-right: 1rem;
}
