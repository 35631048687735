.wrapper {
  display: flex;
  flex-flow: column;
  .header {
    text-transform: uppercase;
  }
  .body {
    flex: 1;
    padding-bottom: 3.5rem;
  }

  .footer {
    position: fixed;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 3.5rem;
    z-index: 5000;
    padding: rem(20px) 5%;
    width: 35em;
  }
}
