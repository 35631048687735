@import './vars';

@import '~carbon-components/src/components/form/form';
@import '~carbon-components/src/components/text-input/text-input';
@import '~carbon-components/src/components/button/button';
@import '~carbon-components/src/components/tooltip/tooltip';
@import '~carbon-components/src/components/content-switcher/content-switcher';
@import '~carbon-components/src/components/data-table-v2/data-table-v2';
@import '~carbon-components/src/components/tabs/tabs';
@import '~carbon-components/src/components/inline-loading/inline-loading';
@import '~carbon-components/src/components/search/search';
@import '~carbon-components/src/components/combo-box/combo-box';
@import '~carbon-components/src/components/accordion/accordion';

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}

.am--header-wrapper {
  margin: $spacing-sm 0 $spacing-sm $spacing-sm;
  border-bottom: 1px solid $ui-03;
}

.am--raised {
  @include layer('raised');
}

.am--sidebar-padding {
  margin: $spacing-sm;
}

.am--sidebar-footer {
  border-top: 2px solid $brand-01;
  background-color: $ui-01;
}

.am--sidebar-footer__item {
  @include typescale('delta');
  @include line-height('body');
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-right: rem(64px);
  padding-left: $spacing-sm;
  .label {
    @include typescale('zeta');
    @include line-height('body');
    margin: 0;
  }
  .content {
    font-weight: 600;
  }
}

.am--sidebar-contact {
  margin: $spacing-sm;
  .contact {
    border-left: 3px solid $brand-01;
    margin: $spacing-lg 0;
    padding-left: $spacing-sm;

    &:hover,
    &:focus {
      background-color: rgba($brand-02, 0.1);
      border-top: 1px solid $brand-01;
      border-bottom: 1px solid $brand-01;
      border-right: 1px solid $brand-01;
    }

    .title {
      @include typescale('zeta');
      @include line-height('body');
      font-weight: 600;
    }
    .summary > div {
      padding: 5px;
    }
  }
  .contact:nth-child(even) {
    background: $ui-01;
    &:hover,
    &:focus {
      background-color: rgba($brand-02, 0.1);
      border-top: 1px solid $brand-01;
      border-bottom: 1px solid $brand-01;
      border-right: 1px solid $brand-01;
    }
  }
}
